class AppUrl {
    static BaseURL = 'https://api.dealerstracker.com/api';

    static BidderData = this.BaseURL+'/bidder/Index';
    static BidderInsert = this.BaseURL+'/bidder/Insert';
    static BidderUer = this.BaseURL+'/bidder/bidderUer';
    static DealerData = this.BaseURL+'/dtuser/Index';
    static DealerInsert = this.BaseURL+'/dtuser/Insert';
}

export default AppUrl