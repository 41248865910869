import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import BidderData from './BidderData/BidderData';
import DealerData from './DealerData/DealerData';

class Profile extends Component {

    state={
        vinlotnumber:'',
        vinLotNumbers: []
    }

    // Login Form Submit
    formSubmit = (e) =>{
        e.preventDefault();
        const data={
            lot_id:this.state.vinlotnumber,
        }

        axios.post('/bid/search',data)
            .then((response) => {
                this.setState({vinLotNumbers:response.data.vinLotNumber});
                console.log(this.state.vinLotNumbers);
            })
            .catch((error) => {
                this.setState({message:error.response.data.message})
            });
    }


    render() {
        let name;
        let email;
        let usrID;

        if(this.props.user){
            name = this.props.user.name;
            email = this.props.user.email;
            usrID = this.props.user.id;
        }
        if(!localStorage.getItem('token')){
            return <Redirect to={'login'} />
        }

        /// Show Error Message
        let error="";
        if(this.state.message){
            error=(
                <div>
                    <div class="alert alert-danger" role="alert" >
                        {this.state.message}
                    </div>
                </div>
            )
        } // end error message

        let lotItem="";
        let itemData=this.state.vinLotNumbers;
        if(itemData){
            lotItem=(
                <table className="table table-striped">
                    <thead className="thead-dark">
                    <tr>
                        <th scope="col">Dealer Name</th>
                        <th scope="col">Amount</th>
                        <th scope="col">vinCode</th>
                        <th scope="col">Date</th>
                    </tr>
                    </thead>
                    <tbody>

                        {itemData.map((one, index) => {
                            return (
                                <tr>
                                    <td>{one.bidder_name}</td>
                                    <td>{one.amount}</td>
                                    <td>{one.vin_code}</td>
                                    <td>{one.created_at}</td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>

            )
        }

        return (
            <div class="container"><br></br><br></br>
                <div class="row">
                    <div className="col-sm-6">
                        <div className="card mb-4">
                            <div className="card-body">
                                <h5 className="card-title">User Profile</h5>
                                <p className="card-text">{name}, Hello you are in your profile.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <nav>
                    <div className="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
                        <button className="nav-link active" id="nav-home-tab" data-toggle="tab" data-target="#nav-home"  type="button" role="tab" aria-controls="nav-home" aria-selected="true">
                            <span className="font-weight-bold text-uppercase">Search data</span>
                        </button>
                        <button className="nav-link" id="nav-profile-tab" data-toggle="tab" data-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">
                            <span className="font-weight-bold text-uppercase">My data</span>
                        </button>
                        <button className="nav-link" id="nav-contact-tab" data-toggle="tab" data-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">
                            <span className="font-weight-bold text-uppercase">Dealers</span>
                        </button>
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                        <div className="jumbotron jumbotron-fluid">
                            <div className="container">
                                <h1 className="display-6">Search Bid by LOT number/VIN code</h1>
                                <p className="lead">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.formSubmit}>
                                        {error}
                                        <input class="form-control mr-sm-2" name="vinlotnumber" type="search" placeholder="Search by Lot#,VIN#" required onChange={(e)=>{this.setState({vinlotnumber:e.target.value})}}/>
                                            <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                                    </form>
                                </p>
                            </div>
                        </div>
                        {lotItem}
                    </div>
                    <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <div className="jumbotron">
                            <h3 className="text-center">Basic Data</h3>
                            <ul className="list-group">
                                <li className="list-group-item">Name : {name} </li>
                                <li className="list-group-item">Email : {email} </li>
                            </ul>
                        </div>
                        <div className="jumbotron">
                                <BidderData user={ usrID }/>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                            <DealerData user={ usrID }/>
                    </div>
                </div>
            </div>

        )
    }
}

export default Profile
