import React, { Component,fragment } from 'react';
import RestClient from '../../RestApi/RestClient';
import AppUrl from '../../RestApi/AppUrl';

class DealerData extends Component {


    constructor() {
        super();
        this.state={
            data:[],
            dealerData:[],
            bidderSelected:'',
            dealerName:'',
            dealerEmail:'',
            dealerPass:''
        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.BidderUer+'/'+this.props.user).then(result=>{
            this.setState({data:result});
        }).catch(error=>{
            this.setState({data:[]});
        });

        RestClient.GetRequest(AppUrl.DealerData+'/'+this.props.user).then(result=>{
            this.setState({dealerData:result});
        }).catch(error=>{
            this.setState({dealerData:[]});
        });

    }

    reRender = () => {
        RestClient.GetRequest(AppUrl.BidderUer+'/'+this.props.user).then(result=>{
            this.setState({data:result});
        }).catch(error=>{
            this.setState({data:[]});
        });

        RestClient.GetRequest(AppUrl.DealerData+'/'+this.props.user).then(result=>{
            this.setState({dealerData:result});
        }).catch(error=>{
            this.setState({dealerData:[]});
        });
    };

    // Login Form Submit
    formSubmit = (e) =>{
        e.preventDefault();
        const DealerData={
            user_id:this.props.user,
            bidder_id:this.state.bidderSelected,
            name:this.state.dealerName,
            email:this.state.dealerEmail,
            password:this.state.dealerPass,
        }

        RestClient.PostRequest(AppUrl.DealerInsert, DealerData).then(result=>{
            this.setState({dealerData:result});
        }).catch(error=>{
            this.setState({message:error.response.data.message})
        });

    }



    render() {

        let bidderUser="";
        let bidderItem=this.state.data;

        let dealerTable="";
        let dealerItem=this.state.dealerData;

        if(bidderItem){
            bidderUser=(
                <select className="custom-select" required onChange={(e)=>{this.setState({bidderSelected:e.target.value})}}>
                    <option selected disabled value="">Choose...</option>
                    {bidderItem.map((one, index) => {
                        return (
                            <option value={one.id}>{one.name}=>{one.email}</option>
                        )
                    })}
                </select>

            )
        }

        if(dealerItem){
            dealerTable=(
                <table className="table table-striped">
                    <thead className="thead-dark">
                    <tr>
                        <th scope="col">Bidder</th>
                        <th scope="col">Dealer</th>
                        <th scope="col">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {dealerItem.map((one, index) => {
                        return (
                            <tr>
                                <td>{one.bidder_name}</td>
                                <td>{one.dt_name}</td>
                                <td>
                                    <a href="#" className="btn btn-primary mr-2">Delete</a>
                                    <a href="#" className="btn btn-primary">Edit</a>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>

            )
        }

        return(
            <fragment>
                <div className="jumbotron jumbotron-fluid">
                    <div className="container">
                        <p>Create Dealer's User For Google Chrome Extention.</p>
                        <p>The Google Chrome Extension(Dealers Tracker), will be sent to the dealers email.</p>
                        <button onClick={this.reRender} className="btn btn-primary">
                            Click if bidders are not visible
                        </button>
                    </div>
                    <div className="container">
                        <form onSubmit={this.formSubmit}>
                            <div className="form-row">
                                <div className="col-md-4 mb-3">
                                    <label>Bidder</label>
                                    {bidderUser}
                                </div>
                                <div className="col-md-2 mb-3">
                                    <label htmlFor="validationTooltip04">Dealer User</label>
                                    <input type="text" className="form-control" placeholder="XXXX-0X" required onChange={(e)=>{this.setState({dealerName:e.target.value})}}/>
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label htmlFor="validationTooltip02">Dealer Email</label>
                                    <input type="email" className="form-control" placeholder="Dealer Email" required onChange={(e)=>{this.setState({dealerEmail:e.target.value})}}/>
                                    <div className="valid-tooltip">
                                        Looks good!
                                    </div>
                                </div>
                                <div className="col-md-2 mb-3">
                                    <label htmlFor="validationpPassword05">Password</label>
                                    <input type="password" className="form-control" required onChange={(e)=>{this.setState({dealerPass:e.target.value})}} />
                                </div>
                            </div>
                            <button className="btn btn-primary" type="submit">Add Dealer</button>
                        </form>
                    </div>
                </div>
                {dealerTable}
            </fragment>

        );
    }
}

export default DealerData